import { render, staticRenderFns } from "./LabelPrinters.vue?vue&type=template&id=1c8c4d23&scoped=true"
import script from "./LabelPrinters.vue?vue&type=script&lang=js"
export * from "./LabelPrinters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c8c4d23",
  null
  
)

export default component.exports